var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_reminder_contract") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    model: _vm.form,
                    "data-testid": "contract-reminder-filter-form"
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSearch.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_created_by"),
                                prop: "createdBy"
                              }
                            },
                            [
                              _c("SelectUser", {
                                attrs: {
                                  "data-testid":
                                    "contract-reminder-filter-created-by",
                                  "label-in-value": ""
                                },
                                model: {
                                  value: _vm.form.createdBy,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "createdBy", $$v)
                                  },
                                  expression: "form.createdBy"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_unit_code"),
                                prop: "unitCode"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  "data-testid":
                                    "contract-reminder-filter-unit-code",
                                  "label-in-value": ""
                                },
                                model: {
                                  value: _vm.form.unitCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "unitCode", $$v)
                                  },
                                  expression: "form.unitCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_customer"),
                                prop: "customer"
                              }
                            },
                            [
                              _c("SelectCustomer", {
                                attrs: {
                                  "data-testid":
                                    "contract-reminder-filter-customer",
                                  "label-in-value": ""
                                },
                                model: {
                                  value: _vm.form.customer,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customer", $$v)
                                  },
                                  expression: "form.customer"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid":
                                      "contract-reminder-filter-btn-reset",
                                    loading:
                                      _vm.loading.overdue ||
                                      _vm.loading.reminder
                                  },
                                  on: { click: _vm.onFilterReset }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_reset")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                    "data-testid":
                                      "contract-reminder-filter-btn-find",
                                    loading:
                                      _vm.loading.overdue ||
                                      _vm.loading.reminder
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    type: "card",
                    "data-testid": "contract-reminder-tabs"
                  }
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: _vm.$t("lbl_overdue") } },
                    [
                      _c("a-table", {
                        attrs: {
                          "row-key": "id",
                          size: "small",
                          columns: _vm.columns,
                          "data-source": _vm.overdueContractReminders.data,
                          loading: _vm.loading.overdue,
                          pagination: {
                            showTotal: function() {
                              return _vm.$t("lbl_total_items", {
                                total:
                                  _vm.overdueContractReminders.totalElements
                              })
                            },
                            total:
                              _vm.overdueContractReminders.totalElements || 0,
                            showSizeChanger: true,
                            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                            current:
                              _vm.overdueContractReminders.currentPage || 0,
                            defaultPageSize: _vm.overduePagination.limit
                          },
                          "row-class-name": function(_record, index) {
                            return index % 2 ? "bg-white" : "bg-gray-light"
                          },
                          scroll: { x: 800 }
                        },
                        on: { change: _vm.onOverdueTableChange },
                        scopedSlots: _vm._u([
                          {
                            key: "icNumber",
                            fn: function(_, record) {
                              return [
                                _c(
                                  "p",
                                  {
                                    class: { "text-danger": record.isExpired }
                                  },
                                  [_vm._v(" " + _vm._s(record.icNumber) + " ")]
                                ),
                                _c(
                                  "p",
                                  {
                                    class: { "text-danger": record.isExpired }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(record.rentPeriod) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "unitCode",
                            fn: function(text, record) {
                              return [
                                record.isUnitBackup
                                  ? [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            title: _vm.$t("lbl_unit_backup")
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: {
                                              type: "interaction",
                                              theme: "twoTone",
                                              "two-tone-color": "#52c41a"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(" " + _vm._s(text || "-") + " ")
                                ])
                              ]
                            }
                          },
                          {
                            key: "action",
                            fn: function(_, record) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "sales.internal-contract.view",
                                        params: { id: record.icId }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_proceed")) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: _vm.$t("lbl_reminder") } },
                    [
                      _c("a-table", {
                        attrs: {
                          "row-key": "id",
                          size: "small",
                          columns: _vm.columns,
                          "data-source": _vm.reminderContractReminders.data,
                          loading: _vm.loading.reminder,
                          pagination: {
                            showTotal: function() {
                              return _vm.$t("lbl_total_items", {
                                total:
                                  _vm.reminderContractReminders.totalElements
                              })
                            },
                            total:
                              _vm.reminderContractReminders.totalElements || 0,
                            showSizeChanger: true,
                            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                            current:
                              _vm.reminderContractReminders.currentPage || 0,
                            defaultPageSize: _vm.reminderPagination.limit
                          },
                          "row-class-name": function(_record, index) {
                            return index % 2 ? "bg-white" : "bg-gray-light"
                          },
                          scroll: { x: 800 }
                        },
                        on: { change: _vm.onReminderTableChange },
                        scopedSlots: _vm._u([
                          {
                            key: "icNumber",
                            fn: function(_, record) {
                              return [
                                _c(
                                  "p",
                                  {
                                    class: { "text-danger": record.isExpired }
                                  },
                                  [_vm._v(" " + _vm._s(record.icNumber) + " ")]
                                ),
                                _c(
                                  "p",
                                  {
                                    class: { "text-danger": record.isExpired }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(record.rentPeriod) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "unitCode",
                            fn: function(text, record) {
                              return [
                                record.isUnitBackup
                                  ? [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            title: _vm.$t("lbl_unit_backup")
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: {
                                              type: "interaction",
                                              theme: "twoTone",
                                              "two-tone-color": "#52c41a"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(" " + _vm._s(text || "-") + " ")
                                ])
                              ]
                            }
                          },
                          {
                            key: "action",
                            fn: function(_, record) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "sales.internal-contract.view",
                                        params: { id: record.icId }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_proceed")) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$can("read", "contract-reminder")
            ? _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-dropdown-button",
                    {
                      attrs: {
                        "data-testid": "contract-reminder-btn-download",
                        type: "primary",
                        disabled: _vm.loading.download
                      },
                      on: {
                        click: function($event) {
                          return _vm.onDownload({ key: "all" })
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function() {
                              return [
                                _c("a-icon", { attrs: { type: "setting" } })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "overlay",
                            fn: function() {
                              return [
                                _c(
                                  "a-menu",
                                  { on: { click: _vm.onDownload } },
                                  [
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "overdue",
                                        attrs: {
                                          "data-testid":
                                            "contract-reminder-only-overdue"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "download" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("common.only-text", {
                                                text: _vm.$t("lbl_overdue")
                                              })
                                            ) +
                                            " "
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-menu-item",
                                      {
                                        key: "reminder",
                                        attrs: {
                                          "data-testid":
                                            "contract-reminder-only-reminder"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "download" }
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("common.only-text", {
                                                text: _vm.$t("lbl_reminder")
                                              })
                                            ) +
                                            " "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2561130715
                      )
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " "),
                      _vm.loading.download
                        ? _c("a-icon", { attrs: { type: "loading" } })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }