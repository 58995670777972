import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DownloadRequestParam } from "@/models/interface/download";
import store from "@/store";

export default class ContractReminderMapper {
  static paginationParamToParamDownload(
    requestQueryParams: RequestQueryParams,
    {
      createdBy,
      unitCode,
      customer,
    }: Partial<{
      createdBy: string;
      unitCode: string;
      customer: string;
    }>
  ): DownloadRequestParam {
    const company = store.state["authStore"].authData.companyName || "";
    const searchParams = [
      company,
      createdBy || "All",
      unitCode || "All",
      customer || "All",
    ];

    return {
      ...requestQueryParams,
      params: searchParams.join(","),
    };
  }
}
