import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ContractReminderResponseDto } from "@/models/interface/contract-reminder.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class ContractReminderService extends HttpClient {
  getContractReminders(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ContractReminderResponseDto>> {
    return this.get<Pagination<ContractReminderResponseDto>>(
      Api.ContractReminder,
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadContractReminders(params: DownloadRequestParam): Promise<Blob> {
    return this.get<Blob>(Api.DownloadContractReminder, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const contractReminderService = new ContractReminderService();
